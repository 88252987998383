<template>
  <table class="listado table table-hover m-0 tabla-desktop">
    <thead class="bg-primary text-white">
      <th-ordenado>{{ $t("Nombre") }}</th-ordenado>
      <th>{{ $t("Email") }}</th>
      <th class="th-acciones">{{ $t("Acciones") }}</th>
    </thead>
    <tbody>
      <tr v-for="usuario in usuarios" v-bind:key="usuario.id">
        <td>{{ usuario.nombre }}</td>
        <td>{{ usuario.email }}</td>
        <td nowrap="nowrap" class="td-acciones">
          <botonera-acciones-tabla :usuario="usuario" @usuarioEliminado="onUsuarioEliminado" />
        </td>
      </tr>
    </tbody>
    <sin-registros :count="usuarios.length" :colspan="6"></sin-registros>
  </table>
</template>
<script>
import BotoneraAccionesTabla from "./BotoneraAccionesTabla.vue";
export default {
  props: {
    usuarios: Array,
  },
  methods: {
    onUsuarioEliminado(id) {
      this.$emit("usuarioEliminado", id);
    },
  },
  components: {
    BotoneraAccionesTabla,
  },
};
</script>
