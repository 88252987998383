<template>
  <div class="listado tabla-mobile">
    <filtros-listado-mobile @filtrar="aplicarFiltros">
      <filtro-activo field="activo" />
    </filtros-listado-mobile>

    <fila-compacta v-for="(usuario, idx) in usuarios" v-bind:key="idx" :titulo="usuario.nombre">
      <div>
        {{ $t("Nombre") }}: <span>{{ usuario.nombre }}</span
        ><br />
        {{ $t("Email") }}: <span>{{ usuario.email }}</span>
      </div>

      <botonera-acciones-tabla slot="toolbar" :usuario="usuario" @usuarioEliminado="onUsuarioEliminado" />
    </fila-compacta>

    <h3 class="text-center" v-if="usuarios.length == 0">
      {{ $t("No hay orígenes de datos") }}
    </h3>
  </div>
</template>

<script>
import BotoneraAccionesTabla from "./BotoneraAccionesTabla.vue";

export default {
  props: {
    usuarios: { type: Array, required: true },
  },
  data() {
    return {
      activo: 1,
    };
  },
  components: { BotoneraAccionesTabla },
  methods: {
    onUsuarioEliminado(id) {
      this.$emit("usuarioEliminado", id);
    },
    aplicarFiltros() {
      var query = Object.assign({}, this.$route.query);
      if (this.activo) {
        query.activo = this.activo;
      }
      query.pagina = 1;
      var route = {
        path: this.$route.path,
        query: query,
      };
    },
  },
};
</script>
