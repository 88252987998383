<template>
  <acciones>
    <accion-primaria :label="$t('Editar')" icon="edit" :to="`/usuarios/editar/${usuario.id}`" />

    <acciones-secundarias>
      <accion-eliminar
        @eliminar="eliminarUsuario(usuario)"
        :confirmacion="`¿Desea eliminar el usuario ${usuario.nombre}?`"
      />
    </acciones-secundarias>
  </acciones>
</template>
<script>
import * as apiUsuarios from "@/services/usuarios.js";
export default {
  props: {
    usuario: Object,
  },
  methods: {
    async eliminarUsuario(usuario) {
      await apiUsuarios.eliminar(usuario.id);
      this.$root.$emit("userMessage", this.$t("Usuario eliminado"));
      this.$emit("usuarioEliminado", usuario.id);
    },
  },
};
</script>
